import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Block, Section } from 'core/components/Grid';
import { SimpleLoader } from 'core/components/Loader';

import { LocalStorage } from 'core/decorators';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { denormalizeData, filterRequiredParams } from 'core/utils/api';

import { indentAttributes } from 'site/utils/prop-types/model';

import { IndentWrap } from 'site/components/Wrappers';

import CardKidsQuestions from 'site/cards/CardKidsQuestion';

import { KIDS_QUESTIONS_SLUG } from 'site/constants';

/** Параметр запроса с полями которые нужны для топика */
const fields = filterRequiredParams([CardKidsQuestions], 'fields');

/** Очищение просмотренных ранее топиков */
const clearKidsQuestions = (saveIdInExceptions) => {
  if (typeof window === 'undefined') return;
  LocalStorage.setItem('visitedKidsQuestions', JSON.stringify(saveIdInExceptions ? [saveIdInExceptions] : []));
};

/** Получение просмотренных ранее топиков */
const getKidsQuestions = () => {
  if (typeof window === 'undefined') return [];
  return JSON.parse(LocalStorage.getItem('visitedKidsQuestions')) || [];
};

/** Сохранение топика в просмотренные */
export const addVisitedKidsQuestion = (topicId) => {
  if (typeof window === 'undefined') return;
  const visitedKidsQuestions = getKidsQuestions();
  if (!visitedKidsQuestions.includes(topicId)) {
    visitedKidsQuestions.push(topicId);
    LocalStorage.setItem('visitedKidsQuestions', JSON.stringify(visitedKidsQuestions));
  }
};

export default function KidsQuestions({ indent, ...otherProps }, { bebopApi }) {
  let cardsHtml = '';
  const [topics, setTopics] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const { content, limit } = otherProps;
    setIsLoading(true);
    bebopApi
      .getTopics({
        rubric: KIDS_QUESTIONS_SLUG,
        with_filtered_count: 1,
        limit: 1,
        fields: '',
      })
      .then(countData => {
        const topicsCount = countData.meta.filtered_count;
        const visitedKidsQuestions = getKidsQuestions();
        if (topicsCount - visitedKidsQuestions.length < limit) {
          // осталось мало топиков, очищаем сохранные и запускаем все по второму кругу
          clearKidsQuestions(content ? content.id : null);
        }

        // выбираем рандомный топик, учитывая наш limit, чтобы все топики влезли после оффсет
        const randomIndex = Math.floor(Math.random() * (topicsCount - visitedKidsQuestions.length - (limit - 1)));

        return bebopApi
          .getTopics({
            excluded_ids: visitedKidsQuestions.join(','),
            rubric: KIDS_QUESTIONS_SLUG,
            include: 'content',
            offset: randomIndex,
            sort: 'published_at',
            limit,
            fields,
          })
          .then(denormalizeData);
      })
      .then(kidsTopics => {
        setTopics(kidsTopics);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        return null;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <SimpleLoader />;

  if (topics.length === 1) {
    cardsHtml = <CardKidsQuestions content={topics[0]} />;
  } else if (topics.length === 2) {
    cardsHtml = (
      <Section>
        <Block>
          <CardKidsQuestions content={topics[0]} kidGender='girl' />
        </Block>
        <Block width='20px' />
        <Block>
          <CardKidsQuestions content={topics[1]} kidGender='boy' />
        </Block>
      </Section>
    );
  } else {
    return null;
  }

  return (
    <IndentWrap {...indent && { ...indent }}>
      {cardsHtml}
    </IndentWrap>
  );
}

KidsQuestions.defaultProps = {
  limit: 1,
};

KidsQuestions.propTypes = {
  /** Количество топиков нужного слага */
  topicsCount: PropTypes.number,
  /** Количество карточек нужное для тизера */
  limit: PropTypes.number,
  /** Текущий просматриваемый топик, published_at которого используем для выбора предыдущего и следующего топика */
  content: modelPropTypes(topicAttributes),
  /** Топики для тизера */
  topics: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  /** Объект с параметрами отступов для разных боковых панелей */
  indent: indentAttributes,
  bebopApi: PropTypes.object,
};

KidsQuestions.contextTypes = {
  bebopApi: PropTypes.object,
};
